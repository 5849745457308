









































































































































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import Spinners from "@/components/utilities/Spinners.vue";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import PosterCard from "@/components/shared/PosterCard.vue";
import PostersModule from "@/store/vuex-modules/posters";

import { PosterObject } from "@/types/interfaces";

const posterStore = getModule(PostersModule);

import logVuexModule from "@/store/vuex-modules/log";
const logStore = getModule(logVuexModule);

@Component({
    components: {
        PosterCard,
        PageTitleHeader,
        Spinners
    }
})
export default class PostersGridDisplay extends Vue {
    searchString = "";
    localeTimezone = "";

    /**
     * Computed
     */
    get pageOptions() {
        return this.$store.getters.getPageOptions("posters") || {};
    }

    get posterData() {
        let filteredPosters =
            this.allPosters && this.allPosters.length ? this.allPosters : [];

        if (this.categoryFilter) {
            filteredPosters = filteredPosters.filter(
                (poster: PosterObject) =>
                    poster.categories &&
                    poster.categories.includes(this.categoryFilter)
            );
        }

        if (this.searchTerm) {
            filteredPosters = filteredPosters.filter(
                (poster: PosterObject) =>
                    poster.title
                        .toLowerCase()
                        .includes(this.searchTerm.toLowerCase()) ||
                    (poster.description &&
                        poster.description
                            .toLowerCase()
                            .includes(this.searchTerm.toLowerCase()))
            );
        }

        return filteredPosters.sort((a: PosterObject, b: PosterObject) =>
            a.title.localeCompare(b.title)
        );
    }

    get isFiltered() {
        return this.posterData.length !== this.allPosters.length;
    }

    get textHeader() {
        const pText = this.posterData.length !== 1 ? "posters" : "poster";
        let text = `${this.posterData.length} ${pText} found `;

        if (this.categoryFilter) {
            text += `for Category: ${this.categoryFilter}`;
        }

        if (this.categoryFilter && this.searchTerm) {
            text += " and ";
        }

        if (this.searchTerm) {
            text += `containing "${this.searchTerm}"`;
        }

        return text;
    }

    get categoryList() {
        const categories: Array<string> = [];

        this.allPosters.forEach((poster) => {
            if (poster.categories && poster.categories.length) {
                poster.categories.forEach((category) => {
                    if (!categories.includes(category)) {
                        categories.push(category);
                    }
                });
            }
        });

        return categories.sort();
    }

    get categoryFilter() {
        return this.$route.query && this.$route.query.category
            ? this.$route.query.category.toString()
            : "";
    }

    get searchTerm() {
        return this.$route.query && this.$route.query.term
            ? this.$route.query.term.toString()
            : "";
    }

    get isLoading() {
        return !posterStore.postersLoaded;
    }

    get allPosters() {
        return posterStore.posters.length ? posterStore.posters : [];
    }

    get helpTextContext() {
        let returnValue = "";

        if (this.pageOptions.helpText && this.pageOptions.helpText.context) {
            returnValue = this.pageOptions.helpText.context;
        }

        return returnValue;
    }

    get disclaimerText() {
        return this.pageOptions.disclaimerText
            ? this.pageOptions.disclaimerText
            : "";
    }

    /**
     * Lifecycle
     */
    created() {
        posterStore.getPosters();
        this.searchString = this.searchTerm;
        this.logPageVisit();
    }

    /**
     * Methods
     */
    updateCategory(category: string) {
        const query = this.$route.query ? { ...this.$route.query } : {};
        query.category = category;

        this.$router.push({
            query: query
        });
    }

    resetSearch() {
        this.searchString = "";
        this.$router.push("/posters");
    }

    updateTerm(term: string) {
        const query = this.$route.query ? { ...this.$route.query } : {};
        query.term = term;

        this.$router.push({
            query: query
        });
    }

    logPageVisit() {
        const uid = this.$store.getters.user.id;
        const username = this.$store.getters.user.username;
        const name = this.$store.getters.user.name;

        const logData = {
            type: `PosterLanding`,
            uid,
            username,
            name
        };

        logStore.appendLogEntry(logData).catch((err) => console.log(err));
    }
}
